// App.js
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Components/Navbar";
import { useEffect, useState } from "react";
import { SvgIcon } from "./Components/SvgIcon";
import UserRoutes from "./routes/UserRoutes";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
// import useUserRole from "./hooks/useUserRole";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/config";
import AdminRoutes from "./routes/AdminRoutes";
import ForgotPassword from "./pages/ForgotPassword";
import PaymentSuccess from "./pages/PaymentSuccess";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCloseBtnHide, setIsCloseBtnHide] = useState(true);
  const [routes, setRoutes] = useState(null)
  const navigate = useNavigate()
  // const { user } = useUserRole()
  const location = useLocation();


  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user?.email) {
        const tokenResult = await getIdTokenResult(user);
        const claims = tokenResult.claims;
        if (claims?.isAdmin) {
          setRoutes(AdminRoutes)
        } else {
          setRoutes(UserRoutes);
        }
      } else {
        if(location.pathname !== "/signup"){
          navigate('/login')
        }
      }
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setIsSidebarOpen(false);
        setIsCloseBtnHide(false);
      } else {
        setIsSidebarOpen(true);
        setIsCloseBtnHide(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App min-h-screen">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
      </Routes>
      {
        location.pathname !== "/login" && location.pathname !== "/signup" && location.pathname !== "/payment-success" && location.pathname !== "/forgotPassword" && <div className="main relative">
          {isSidebarOpen && (
            <div className="left-content h-[100vh]">
              <button
                className={`fixed w-10 bg-[#0F28A4] h-10 rounded-r-full flex items-center justify-center top-6  text-white left-[255px] z-50 ${isCloseBtnHide ? "hidden" : ""
                  }`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <SvgIcon name={"arrowLeft"} />
              </button>
              <Navbar setIsSidebarOpen={setIsSidebarOpen}/>
            </div>
          )}
          <div className="flex flex-col lg:h-auto justify-between p-[15px]  w-full md:w-[calc(100%-270px)]">
            <Routes className="mainContent overflow-y-auto">

              {routes?.map(({ path, exact, element: Element }, index) => (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                  element={
                    <Element
                      isSidebarOpen={isSidebarOpen}
                      setIsSidebarOpen={setIsSidebarOpen}
                    />
                  }
                />
              ))}
            </Routes>
          </div>
        </div>
      }

    </div>
  );
}

export default App;
