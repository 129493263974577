
import "./QuizQuestionPage.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import QuizLoader from "../ResumeTrainingPage/QuizLoader";
import QuestionResponse from "../QuizQuestionPage/QuestionResponse";
import QuizResultCard from "../Components/QuizzResultCard";
import { useGlobal } from "../context/GlobalContext";
import { doc, increment, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/config";
import QuizSummary from "../ResumeTrainingPage/QuizSummary";
import toast from "react-hot-toast";
import QuizTimer from "../Components/QuizTimer";
import { resultCriteria } from "../data/resultCriteria";
import QuestionsLoader from "../Components/QuestionsLoader";
const API_URL = process.env.REACT_APP_API_URL;
const ENV = process.env.REACT_APP_ENV;

export default function QuizQuestionPage({
  quiz,
  sectionId,
  sectionName,
  moduleId,
}) {
  const { userProgress, handleItemCompletion } = useGlobal();
  const navigate = useNavigate();
  const [isStart, setIsStart] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState({
    key: null,
    tickedAnswer: null,
  });
  const [answers, setAnswers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [verificationRes, setVerificationRes] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);
  console.log(`Running in ${ENV} environment`);

  const handleStartQuiz = async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    const token = await user.getIdToken();
    try {
      const res = await axios.get(
        `${API_URL}/quiz/questions`,
        {
          params: {
            quizType: quiz.quizType,
            level: quiz.level,
            moduleId: quiz.module.moduleId,
            quizNumber: quiz.quizNumber,
            sectionId: quiz?.sectionId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = await res.data;
      if (result) {
        setQuestions(result.questions);
        setCurrentQuestion(result.questions[0]);
        setStartTime(Date.now());
        setIsStart(true);
      } else {
        toast.error("No questions found for this quiz!");
        return;
      }
    } catch (error) {
      console.log("error ", error);
      toast.error("Failed to start quiz. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionSelect = (key, tickedAnswer, questionId) => {
    setSelectedOption({ key, tickedAnswer, questionId });
  };

  const handleShowAnswer = async () => {
    if (!selectedOption.tickedAnswer) {
      toast.error("Veuillez sélectionner une option", {
        position: "top-center",
      });
      return;
    }
    setIsValidating(true);
    try {
      if (selectedOption.tickedAnswer) {
        const res = await axios.post(
          `${API_URL}/quiz/verify-answer`,
          {
            questionId: selectedOption.questionId,
            selected: selectedOption.key,
          }
        );
        const verify = await res.data.data;
        setVerificationRes({
          explanation: verify.explanation,
          selected: verify.selected,
          options: verify.options,
          result: verify.result,
        });
        setResultArray((prev) => [...prev, verify.result]);
        setAnswers([...answers, selectedOption.tickedAnswer]);
      }
      setShowAnswer(true);
    } catch (error) {
      console.error("Error verifying answer:", error);
      toast.error(
        "Une erreur s'est produite lors de la vérification de la réponse."
      );
    } finally {
      setIsValidating(false);
    }
  };

  const handleSaveProgress = async () => {
    const nextItem = await handleItemCompletion(quiz, sectionId, moduleId);
    if (nextItem) {
      navigate(
        `/resume/course-content/${sectionName}/${nextItem.module.title}/${nextItem.type}/${nextItem.id}`
      );
    }
  };

  const handleCompleteQuiz = async (quizId) => {
    const quizAttemptsRef = doc(db, "quizAttempts", userProgress?.id);

    const score = resultArray.filter((result) => result === true).length;
    const timeTaken = calculateTimeTaken();
    const [minutes, seconds] = timeTaken.split(":").map(Number);
    const totalSeconds = minutes * 60 + seconds;

    const quizData = {
      id_quiz: quizId,
      score: +score,
      temps_reponse: +totalSeconds,
      difficulte: quiz.difficulty || "intermédiaire",
      tentatives: increment(1),
      minRequiredScore: resultCriteria[`${quiz?.quizType}`].gradeB,
    };

    if (score >= resultCriteria[`${quiz?.quizType}`].gradeB) {
      setIsFinished(true);
      await setDoc(
        quizAttemptsRef,
        {
          [quizId]: { ...quizData },
        },
        { merge: true }
      );
    } else {
      await setDoc(
        quizAttemptsRef,
        {
          [quizId]: { ...quizData },
        },
        { merge: true }
      );

      setIsFinished(true);
    }
    setIsFinished(true);
  };

  const handleNext = async () => {
    const user = auth.currentUser;
    const token = await user.getIdToken();

    if (selectedOption.tickedAnswer) {
      setIsContinuing(true);

      try {
        if (currentQuizIndex < questions.length - 1) {
          const res = await axios.get(
            `${API_URL}/quiz/questions/${
              questions[+currentQuizIndex + 1]
            }`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const nextQuestion = await res.data.data;
          setCurrentQuestion(nextQuestion);
          setCurrentQuizIndex((prev) => +prev + 1);

          setSelectedOption({ key: null, tickedAnswer: null });
          setShowAnswer(false);
        } else {
          await handleCompleteQuiz(quiz?.id);
        }
      } catch (error) {
        console.error("Error moving to next question:", error);
        toast.error("Une erreur s'est produite. Veuillez réessayer.");
      } finally {
        setIsContinuing(false);
      }
    } else {
      alert("Please select an option!");
    }
  };

  const calculateTimeTaken = () => {
    const endTime = Date.now();
    const timeTakenMs = endTime - startTime;
    const minutes = Math.floor(timeTakenMs / 60000);
    const seconds = Math.floor((timeTakenMs % 60000) / 1000);
    const timeTaken = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return timeTaken;
  };

  const handleTimeUp = () => {
    handleCompleteQuiz(quiz?.id);
  };

  const orderedOptions = ["option1", "option2", "option3", "option4"];

  return (
    <div className="w-[100%]">
      <div className="">
        {!isStart ? (
          <div className="w-full">
            <QuizSummary
              quiz={quiz}
              isLoading={isLoading}
              handleStartQuiz={handleStartQuiz}
              setSelectedOption={setSelectedOption}
            />
          </div>
        ) : (
          <div className="quiz-page-content flex lg:h-[87vh] p-[17px_15px] flex-col flex-shrink-0 w-full rounded-[15px] border border-[rgba(255,255,255,0.3)] bg-[rgba(0,0,0,0.65)] overflow-y-scroll overflow-x-hidden ">
            <div className="question-tracker-header relative">
              {isFinished ? (
                <>
                  <h2 className="header-title-h2 text-white">{`${quiz?.title} - Fin du quiz`}</h2>
                  <div className="quiz-loader">
                    <QuizLoader
                      quizCompleted={questions?.length}
                      totalQuiz={questions?.length}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center px-4 w-fit gap-3 p-2 rounded-xl absolute top-[75px]">
                    <QuizTimer
                      startTime={startTime}
                      duration={quiz.duration * 60}
                      onTimeUp={handleTimeUp}
                    />
                  </div>
                  <h2 className="text-left font-semibold text-[14px] lg:text-xl text-white">{`${
                    quiz?.title
                  } - Question ${currentQuizIndex + 1}/${
                    questions?.length
                  }`}</h2>
                  <div className="quiz-loader">
                    <QuizLoader
                      quizCompleted={currentQuizIndex}
                      totalQuiz={questions?.length}
                    />
                  </div>
                </>
              )}
            </div>
            {isLoading ? (
              <QuestionsLoader />
            ) : (
              !isFinished &&
              !showAnswer && (
                <div className="flex flex-col justify-center gap-[100px] h-full w-full">
                  <div className="flex flex-col items-center gap-7 w-full">
                    <h2 className="text-white text-[15px]">
                      {currentQuestion?.questionText}
                    </h2>
                    <div className=" flex flex-col w-full lg:w-[70%] gap-[10px]">
                      {orderedOptions.map(
                        (key) =>
                          currentQuestion?.options[key] && (
                            <button
                              key={key}
                              onClick={() =>
                                handleOptionSelect(
                                  key,
                                  currentQuestion.options[key],
                                  currentQuestion.id
                                )
                              }
                              className={`text-[9px] text-start lg:text-[16px] flex items-center justify-start p-2 text-white text-sm rounded-xl transition duration-300 ease-in-out ${
                                selectedOption.key === key
                                  ? "border-2 border-[#0075FF]"
                                  : "border-2 border-[#3D4364]"
                              }`}
                            >
                              <div
                                className={`option-number ${
                                  selectedOption.key === key
                                    ? "bg-[#0B2A6A] text-[#0075FF]"
                                    : "bg-white bg-opacity-25 text-white"
                                }`}
                              >
                                {key.replace("option", "")}
                              </div>
                              {currentQuestion.options[key]}
                            </button>
                          )
                      )}
                    </div>
                    <div className="flex w-full lg:w-[70%] p-2 h-[56px] justify-center items-center gap-[10px] rounded-[12px] text-white bg-[#0075FF] text-center mt-5">
                      <button
                        onClick={() => handleShowAnswer()}
                        className="next-button"
                        disabled={isValidating}
                      >
                        {isValidating ? (
                          <ClipLoader color="#ffffff" size={20} />
                        ) : (
                          "Valider"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )
            )}

            {!isFinished && showAnswer && (
              <div className=" flex flex-col justify-center gap-[100px] h-full w-full items-center">
                <div className="w-full 2xl:w-[70%] mt-14 sm:mt-0">
                  <div className="flex flex-col gap-10">
                    <QuestionResponse
                      responseResult={verificationRes.result}
                      selectedOption={selectedOption}
                      selectedOptionKey={selectedOption?.key}
                      selectedOptionTickedAnswer={selectedOption?.tickedAnswer}
                      rightDescription={verificationRes?.explanation}
                      wrongDescription={verificationRes?.explanation}
                      otherOption={currentQuestion?.options}
                    />

                    <div className="continue-ans-btn">
                      <button
                        className={`continue-btn ${
                          verificationRes.result
                            ? "bg-[#32BD9C]"
                            : "bg-[#EC6767]"
                        }`}
                        onClick={() => handleNext()}
                        disabled={isContinuing}
                      >
                        {isContinuing ? (
                          <ClipLoader color="#ffffff" size={20} />
                        ) : (
                          "Continuer"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isFinished && (
              <div className="w-full flex items-center justify-center h-full">
                <QuizResultCard
                  score={
                    resultArray?.filter((result) => result === true).length
                  }
                  handleStartQuiz={handleStartQuiz}
                  quizType={quiz?.quizType}
                  totalQuestions={questions.length}
                  timeTaken={calculateTimeTaken()}
                  minRequiredScore={resultCriteria[`${quiz?.quizType}`].gradeB}
                  setIsFinished={setIsFinished}
                  setStartTime={setStartTime}
                  setShowAnswer={setShowAnswer}
                  setAnswers={setAnswers}
                  setCurrentQuizIndex={setCurrentQuizIndex}
                  totalMarks={quiz?.totalMarks}
                  handleSaveProgress={handleSaveProgress}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
