import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CheckCircle } from "lucide-react";
import Logo from "../Images/Logo.svg";
const ENV = process.env.REACT_APP_ENV;

const PaymentSuccess = () => {
  const location = useLocation();
  

  const trigerGoogleTags = (clientSecret, paymentIntent) => {
    if (window.gtag) {
      try {
        window.gtag("event", "conversion", {
          send_to: "AW-16489125436/13yKCN2on50ZELys0LY9",
          transaction_id: clientSecret.split("_secret_")[0],
        });


        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });

        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: clientSecret.split("_secret_")[0],
            value: paymentIntent.amount || 0,
            currency: paymentIntent.currency,
          }
        });

        window.gtag("event", "purchase", {
          transaction_id: clientSecret.split("_secret_")[0],
          value: paymentIntent.amount || 0,
          currency: paymentIntent.currency,
        });

      } catch (e) {
        console.log("Google analytics tracking error", e);
      }
    }
  };
  useEffect(() => {
    const clientSecret = location.state?.client_secret;
    const paymentIntent = location.state?.paymentIntent;
    if(clientSecret && paymentIntent ){
      trigerGoogleTags(clientSecret, paymentIntent);
    }
  }, []);
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col md:flex-row">
      {/* Left Panel */}
      <div className="bg-[#03163F] w-full md:w-1/2 p-10 flex justify-center items-center">
        <div className="flex flex-col items-center justify-center gap-6 w-full">
          <div className=" flex items-center gap-2">
            <img src={Logo} width={80} height={80} alt="Logo" />
            <div className="flex flex-col text-white font-normal">
              <span>ChatGPT</span>
              <span>Academy</span>
            </div>
          </div>
          <div className="text-white flex flex-col items-center">
            <h1 className="text-3xl font-bold mb-4">
              Bienvenue dans la Chatgpt Academy !
            </h1>
            <div className="flex flex-col items-center justify-center w-[70%]">
              <p className="text-xl w-full ">
                Votre inscription est bien confirmée !
              </p>
              <p className="text-xl w-full ">
                Vous êtes prêt(e) à rejoindre l'aventure !
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <CheckCircle className="w-24 h-24 text-green-400 mb-6" />
            <div className="bg-white/10 rounded-lg p-6 w-full">
              <h2 className="text-white text-xl font-semibold mb-4">
                Prochaines étapes:
              </h2>
              <ul className="text-white space-y-3">
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-green-400 rounded-full mr-3"></div>
                  Connectez-vous à votre compte
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-green-400 rounded-full mr-3"></div>
                  Complétez votre profil
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-green-400 rounded-full mr-3"></div>
                  Commencez votre formation
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Right Panel */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-10">
        <div className="max-w-md w-full">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-[#30313D] mb-4">
              Paiement réussi !
            </h2>
            <p className="text-gray-600">
              Merci pour votre confiance. Un email de confirmation a été envoyé
              à votre adresse.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-600">Status</span>
              <span className="text-green-500 font-semibold">Confirmé</span>
            </div>
            <div className="w-full h-[1px] bg-gray-200 mb-4"></div>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Formation</span>
                <span className="font-semibold">Chatgpt Academy</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Accès</span>
                <span className="font-semibold">Immédiat</span>
              </div>
            </div>
          </div>

          <Link
            to="/login"
            className="w-full block text-center py-3 px-4 rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
          >
            se connecter
          </Link>

          <p className="mt-6 text-center text-sm text-gray-600">
            Des questions?{" "}
            <a
              href="https://discord.com/invite/ZMPUNgMKgw"
              target="_blank"
              className="text-indigo-600 hover:text-indigo-500"
            >
              &nbsp; Rejoignez notre Discord
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
