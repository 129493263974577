import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { auth } from "../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";
import toast from "react-hot-toast";
import SubmitButton from "../Components/SubmitButton";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm();

  const [currentError, setCurrentError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleValidation = () => {
    const values = getValues();
    clearErrors();
    setCurrentError("");

    if (!values.email) {
      setError("email", { type: "required", message: "L'e-mail est requis" });
      setCurrentError("L'e-mail est requis");
      return false;
    }

    return true;
  };

  const onSubmit = async (data) => {
    const { email } = data;
    try {
      await sendPasswordResetEmail(auth, email);

      toast.success("Lien de réinitialisation envoyé par e-mail", {
        position: "top-center",
      });
      setIsSubmitted(true);
    } catch (error) {
      setCurrentError(error.message);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center justify-center w-full h-full">
        <main className="bg-bgColor w-[90%] md:w-[40%] py-10 p-6 rounded-lg border border-borderColor flex flex-col gap-12">
          {currentError && (
            <div className="flex h-[60px] p-4 px-5 items-center gap-2.5 rounded-[15px] border-[1px] border-borderColor bg-[#FFE9E9] w-[250px] sm:w-[fit-content] mt-4">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Page-1">
                    <g id="Icon-Set-Filled">
                      <path
                        id="cross-circle"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.5356 12.65C13.78 12.8937 13.78 13.2938 13.5356 13.5375C13.2919 13.7813 12.8956 13.7813 12.6513 13.5375L10.0037 10.8875L7.33749 13.5562C7.09124 13.8 6.69252 13.8 6.44627 13.5562C6.20064 13.3062 6.20064 12.9063 6.44627 12.6625L9.11251 9.99374L6.465 7.35001C6.22062 7.10626 6.22062 6.70623 6.465 6.46248C6.70812 6.21873 7.10437 6.21873 7.34875 6.46248L9.99626 9.11247L12.6825 6.42502C12.9287 6.18127 13.3269 6.18127 13.5731 6.42502C13.8187 6.67502 13.8187 7.06873 13.5731 7.31873L10.8875 10.0063L13.5356 12.65ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0Z"
                        fill="#CE0500"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div>{currentError}</div>
            </div>
          )}

          {isSubmitted ? (
            <div className="text-center text-white">
              L'email a bien été envoyé. Tu y trouveras ton lien pour
              réinitialiser ton mot de passe.
              <div>
                <Link
                  to="/login"
                  className="text-[#113BE1]  underline cursor-pointer"
                >
                  Connexion
                </Link>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-8 py-6 px-0">
              <div className="flex flex-col w-full lg:px-20">
                <h2 className="text-3xl font-bold text-white mb-10">
                  Réinitailise ton mot de passe
                </h2>
                <form
                  onSubmit={handleSubmit((data) => {
                    if (handleValidation()) {
                      onSubmit(data);
                    }
                  })}
                  className="flex flex-col w-full gap-4"
                >
                  <label className="flex flex-col gap-2">
                    <span className="text-white text-lg flex justify-start">
                      Adresse mail
                    </span>
                    <input
                      type="email"
                      id="email"
                      {...register("email")}
                      placeholder="Entre ton adresse mail"
                      style={{ backgroundColor: "transparent" }}
                      className="py-2 px-4 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-white focus:border-[#ffffff40] outline-none"
                    />
                    {errors.email && (
                      <span className="text-red-500">
                        {errors.email.message}
                      </span>
                    )}
                  </label>

                  <SubmitButton
                    buttonText="Envoie du lien de réinitialisation"
                    type="submit"
                    width="full"
                  />

                  <div className="text-white">
                    Tu te souviens de ton mot de passe ?{" "}
                    <Link
                      to="/login"
                      className="text-[#113BE1]  underline cursor-pointer"
                    >
                      Connexion
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default ForgotPassword;
