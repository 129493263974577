// src/routes/routes.js

import MyProfile from "../pages/MyProfile";
import ResumeTraining from "../pages/ResumeTraining";
import Ranking from "../pages/Ranking";
import MyRewards from "../pages/MyRewards";
import Settings from "../pages/Settings";
import CourseContent from "../ResumeTrainingPage/CourseContent";

import QuizQuestionPage from "../pages/QuizQuestionPage";
import PaymentSuccess from "../pages/PaymentSuccess";

const UserRoutes = [
  {
    path: "/",
    exact: true,
    element: MyProfile,
  },
  {
    path: "/resume",
    exact: true,
    element: ResumeTraining,
  },
  {
    path: "/reward",
    exact: true,
    element: MyRewards,
  },
  {
    path: "/ranking",
    exact: true,
    element: Ranking,
  },
  {
    path: "/settings",
    exact: true,
    element: Settings,
  },
  {
    path: "/payment-success",
    exact: true,
    element: PaymentSuccess,
  },
  {
    path: "/resume/course-content/:sectionName/:moduleName/:contentType/:id",
    exact: true,
    element: CourseContent,
  },
 
  {
    path: "/settings/profile-modify",
    exact: true,
    element: QuizQuestionPage,
  }
];

export default UserRoutes;
