import React, { useEffect, useRef, useState } from "react";
import { usePlyr } from "plyr-react";
import "plyr-react/plyr.css";

const CustomPlyrInstance = React.forwardRef((props, ref) => {
  const { source, options, handleVideoComplete, sectionId, moduleId, currentItem } = props;
  const raptorRef = usePlyr(ref, { options, source });
 
  useEffect(() => {
    const { current } = ref;
    if (current?.plyr?.source === null) return;
    const api = current;
    api.plyr.on("ready", () => console.log("I'm ready"));
    api.plyr.on("ended", () => handleVideoComplete(currentItem, sectionId, moduleId));
   
  });

  return (
    <video
      ref={raptorRef}
      className="plyr-react plyr"
      playsInline
    />
  );
});

const Player = ({ videoSource, currentItem, handleVideoEnd, moduleId, sectionId, plyrOptions }) => {
  const ref = useRef(null);

  const [isMobile, setIsMobile] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(()=>{
    var safari=/^((?!chrome|android).)safari/i.test(navigator?.userAgent);
    var firefox=/^((?!chrome|android).)firefox/i.test(navigator?.userAgent);

    const checkMobile = () => {
      const ua = navigator.userAgent;
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua));
    };

   if(safari)
   setIsSafari(true);
   if(firefox){
    setIsFirefox(firefox);
   }
   checkMobile();
  },[isSafari])

  let adjustedVideoSource = isSafari ? [videoSource[0]] : videoSource;
  adjustedVideoSource = isFirefox ? [videoSource[1], videoSource[2]] : adjustedVideoSource;

  const adjustedPlyrOptions = {
    ...plyrOptions,
    fullscreen: { enabled: true, fallback: true, iosNative: true },
    settings: plyrOptions?.settings?.filter(setting => isSafari ? setting !== "quality" : setting)
  };

  if (isMobile) {
    adjustedPlyrOptions.controls = ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay', 'fullscreen'];
  }

  return (
    <div className="wrapper">
      {adjustedVideoSource && (
        <CustomPlyrInstance
          ref={ref}
          source={{
            type: 'video',
            sources: adjustedVideoSource,
            poster: currentItem?.thumbnailUrl,
          }}
          options={adjustedPlyrOptions}
          handleVideoComplete={handleVideoEnd}
          moduleId={moduleId}
          sectionId={sectionId}
          currentItem={currentItem}
        />
      )}
    </div>
  );
};

export default Player;