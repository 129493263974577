import React from 'react';

export default function SubmitButton({ buttonText, type = "button", width }) {
  return (
    <div>
      <button 
        type={type} 
        className={`flex h-12 py-4 px-5 justify-center items-center gap-2.5 rounded-[15px] bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1] text-white text-base font-medium text-center mt-7 text-[0.890rem] sm:text-[1rem] ${width ? `w-${width}`: ''}`}
      >
        {buttonText}
      </button>
    </div>
  );
}
