import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/config";
import toast from "react-hot-toast";
import {
  getIdTokenResult,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import LogoImage from "../Images/Logo.png";
import { doc, setDoc } from "firebase/firestore";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currentError, setCurrentError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleValidation = () => {
    setCurrentError("");

    if (!email) {
      setCurrentError("L'adresse mail est requis");
      return false;
    }

    if (!password) {
      setCurrentError("Le mot de passe est requis");
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation()) return;

    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const tokenResult = await getIdTokenResult(user);
      const claims = tokenResult?.claims;

      if (claims.isAdmin) {
        navigate("/admin/home");
      } else if (claims.payment) {
        const userRef = doc(db, "users", user.uid);
        await setDoc(
          userRef,
          { lastSignin: new Date(Date.now()) },
          { merge: true }
        );

        navigate("/");
      } else {
        await signOut(auth);
        toast.remove();
        toast.error("Accès non autorisé, déconnexion..!", {
          position: "top-center",
          duration: 4000,
        });
      }
    } catch (error) {
      setCurrentError("Ton adresse e-mail ou ton mot de passe est incorrect");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="mb-10 flex items-center gap-2">
          <img src={LogoImage} width={80} height={80} alt="Logo" />
          <div className="flex flex-col text-white font-normal">
            <span>ChatGPT</span>
            <span>Academy</span>
          </div>
        </div>
        <main className="bg-bgColor w-[90%] md:w-[40%] relative py-10 p-6 rounded-lg border border-borderColor flex flex-col gap-12">
          {currentError && (
            <div className="flex h-[60px] p-4 mx-auto px-5 items-center gap-2.5 rounded-[15px] border-[1px] border-borderColor bg-[#FFE9E9] w-[250px] sm:w-fit absolute -top-18 left-[50%] -translate-x-[50%]">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5356 12.65C13.78 12.8937 13.78 13.2938 13.5356 13.5375C13.2919 13.7813 12.8956 13.7813 12.6513 13.5375L10.0037 10.8875L7.33749 13.5562C7.09124 13.8 6.69252 13.8 6.44627 13.5562C6.20064 13.3062 6.20064 12.9063 6.44627 12.6625L9.11251 9.99374L6.465 7.35001C6.22062 7.10626 6.22062 6.70623 6.465 6.46248C6.70812 6.21873 7.10437 6.21873 7.34875 6.46248L9.99626 9.11247L12.6825 6.42502C12.9287 6.18127 13.3269 6.18127 13.5731 6.42502C13.8187 6.67502 13.8187 7.06873 13.5731 7.31873L10.8875 10.0063L13.5356 12.65ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0Z"
                    fill="#CE0500"
                  />
                </svg>
              </div>
              <div>{currentError}</div>
            </div>
          )}

          <div className="flex flex-col items-center gap-8 py-6 px-0 ">
            <div className="flex flex-col w-full lg:px-20">
              <h2 className="text-4xl font-bold text-white mb-10">Connexion</h2>
              <form onSubmit={onSubmit} className="flex flex-col w-full gap-8">
                <label className="flex flex-col gap-2 ">
                  <span className="text-white text-lg flex justify-start">
                    Adresse mail
                  </span>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setCurrentError("");
                    }}
                    placeholder="email@domain.com"
                    style={{ backgroundColor: "transparent" }}
                    className="py-3 px-4 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-[#ffffff7d] focus:border-[#ffffff40] outline-none"
                  />
                </label>
                <label className="flex flex-col">
                  <span className="text-white text-lg flex justify-start">
                    Mot de passe
                  </span>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setCurrentError("");
                    }}
                    placeholder="******"
                    style={{ backgroundColor: "transparent" }}
                    className="py-3 px-4 rounded-lg border border-[#ffffff1a] w-full text-white placeholder:text-[#ffffff7d] focus:border-[#ffffff40] outline-none"
                  />
                </label>

                <button
                  type="submit"
                  className={`py-2 px-4 rounded-lg w-full text-white bg-blue-600 ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? "Connexion..." : "Connexion"}
                </button>

                <div>
                  <span className="text-white">
                    Tu n'as pas de compte ?{" "}
                    <Link
                      to="/signup"
                      className="text-[#113BE1] underline cursor-pointer"
                    >
                      Inscription
                    </Link>
                  </span>
                </div>
                <div>
                  <span className="text-white">
                    Mot de passe oublié ?{" "}
                    <Link
                      to="/forgotPassword"
                      className="text-[#113BE1] underline cursor-pointer"
                    >
                      Réinitialiser
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Login;
