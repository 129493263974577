import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import Logo from "../Images/Logo.svg";
import toast from "react-hot-toast";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
const ENV = process.env.REACT_APP_ENV;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { locale: "fr" });


const SkeletonLoader = () => (
  <div className="animate-pulse space-y-6">
    <div className="space-y-2">
      <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      <div className="h-10 bg-gray-200 rounded"></div>
    </div>
    <div className="space-y-2">
      <div className="h-4 bg-gray-200 rounded w-1/3"></div>
      <div className="h-10 bg-gray-200 rounded"></div>
    </div>
    <div className="space-y-2">
      <div className="h-4 bg-gray-200 rounded w-1/4"></div>
      <div className="h-10 bg-gray-200 rounded"></div>
    </div>
    <div className="space-y-2">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      <div className="h-40 bg-gray-200 rounded"></div>
    </div>
    <div className="h-12 bg-gray-200 rounded"></div>
  </div>
);

const PaymentComponent = ({
  userDetails,
  onPaymentSuccess,
  disabled,
  clientSecret,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handlePaymentAndSignup = async () => {
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message);
      }

      const functions = getFunctions();
      const updatePaymentIntent = httpsCallable(
        functions,
        "updatePaymentIntent"
      );
      if (window && ENV === "production") {
        window.lintrk('track',{ conversion_id:1895034})
      }
      const result = await updatePaymentIntent({
        ...userDetails,
        paymentIntentId: clientSecret.split("_secret_")[0],
      });

      if (result.data.error) {
        throw new Error(result.data.error);
      }

      const { updatedClientSecret } = result.data;

      const paymentResult = await stripe.confirmPayment({
        elements,
        clientSecret: updatedClientSecret,
        confirmParams: {
          return_url: "https://training.chatgpt-academy.fr/payment-success",
        },
        redirect: "if_required",
      });

      if (paymentResult.error) {
        throw new Error(paymentResult.error.message);
      }
 
      if (paymentResult.paymentIntent.status === "succeeded") {
        onPaymentSuccess(paymentResult.paymentIntent, clientSecret);
      }
    } catch (err) {
      setError(err.message);
      toast.error("Payment failed. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <PaymentElement />
      <button
        onClick={handlePaymentAndSignup}
        disabled={disabled || !stripe || processing}
        className="w-full mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        {processing ? "Processing..." : "Finaliser le paiement"}
      </button>
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </div>
  );
};

const Signup = () => {
  const [loading, setLoading] = useState(true);
  const [isComponentLoading, setIsComponentLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [priceData, setPriceData] = useState(null);

  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isValid },
    watch,
  } = useForm({ mode: "onChange" });

  const watchedFields = watch();

  const createInitialPaymentIntent = async (amount, currency) => {
    setIsComponentLoading(true);
    try {
      const functions = getFunctions();
      const createPaymentIntent = httpsCallable(
        functions,
        "createPaymentIntent"
      );

      const result = await createPaymentIntent({
        amount: amount * 100,
        currency: currency,
      });
      if (result.data.error) {
        throw new Error(result.data.error);
      }

      setClientSecret(result.data.clientSecret);
      setIsComponentLoading(false);
    } catch (err) {
      toast.error("Failed to initialize payment. Please try again.");
    }
  };

  const fetchPriceData = async () => {
    try {
      const priceDocRef = collection(db, "coursePrice");
      const priceDocSnap = await getDocs(priceDocRef);

      if (priceDocSnap.docs[0].data()) {
        setPriceData({
          id: priceDocSnap.docs[0].id,
          ...priceDocSnap.docs[0].data(),
        });
        const totalPrice = priceDocSnap.docs[0].data().totalPrice;
        await createInitialPaymentIntent(totalPrice, "EUR");
      } else {
      }
    } catch (error) {
      // console.error("Error fetching price data:", error);
    }
  };

  useEffect(() => {
    fetchPriceData().then(() => {
      setLoading(false);
    });
  }, []);

  const appearance = {
    variables: {
      fontFamily: "Poppins, sans-serif",
    },
  };

  const handlePaymentSuccess = async (paymentIntent, client_secret) => {

    try {
      setTimeout(() => {
        navigate("/payment-success", { state: { client_secret, paymentIntent } });
      }, 1500);
    } catch (error) {
      toast.error("An error occurred after payment. Please contact support.");
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen bg-gray-100">
      <div className="bg-[#03163F] p-10 flex justify-center items-start">
       
        <div className="flex flex-col items-center justify-center gap-6 w-full sm:w-[80%] md:w-[60%] lg:[40%]">
        { ENV === 'staging' && 
          <div className="text-sm text-gray-500">
          Environment: {ENV}
        </div>
        }
          <div className="mt-6 w-full items-center flex gap-2 text-2xl md:text-3xl font-normal text-white">
            <img src={Logo} alt="Logo" />
            <p>Chatgpt Academy</p>
          </div>
          {isComponentLoading ? (
            <SkeletonLoader />
          ) : (
            <>
              <div className="w-full">
                <h2 className="font-normal text-left text-white text-xl md:text-xl">
                  Formation Chatgpt Academy
                </h2>
                <p className={`${priceData?.discountPercentage === 0 ? "" : "line-through"} text-left text-white font-normal text-3xl`}>
                  {priceData?.originalPrice} &#8364;
                </p>
              </div>
              {priceData?.discountPercentage > 0 && (
                <div className="w-full">
                  <p className="text-left text-white text-xl md:text-xl">
                    🚨 OFFRE LIMITEE (-{parseFloat(priceData?.discountPercentage).toFixed(0)}%)
                  </p>
                  <p className="text-left text-white font-normal text-3xl">
                    {priceData?.discountedPrice} &#8364;
                  </p>
                </div>
              )}

              <div className="calPrice w-full">
                <div className="flex justify-between items-center">
                  <p className="text-left text-white font-bold text-lg">
                    Chatgpt Academy <br />{" "}
                    <span className="text-sm font-normal">Formation IA</span>
                  </p>
                  <p className="font-bold text-left text-white text-lg">
                    {priceData?.discountedPrice} &#8364;
                  </p>
                </div>
                <div className="w-full h-[1px] my-4 text-white bg-white"></div>
                <div className="flex flex-col gap-6">
                  <div className="flex justify-between items-center">
                    <p className="text-left text-white text-lg">Sous-total</p>
                    <p className="text-left text-white text-lg">
                      {priceData?.discountedPrice} &#8364;
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-left text-white text-lg">Taxe</p>
                    <p className="text-left text-white text-lg">
                      {priceData?.taxPercentage}% &#8364;
                    </p>
                  </div>
                </div>
                <div className="w-full h-[1px] my-4 text-white bg-white"></div>
                <div className="flex justify-between items-center">
                  <p className="text-left text-white text-lg">Montant total</p>
                  <p className="text-left text-white text-lg">
                    {priceData?.totalPrice} &#8364;
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col w-[100%] justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-14 xl:px-16">
        <h1 className="font-bold text-2xl mb-6 text-[#30313D]">
          Inscription à la Chatgpt Academy
        </h1>

        <div className="mx-auto w-full  lg:w-[55%]">
          {isComponentLoading ? (
            <SkeletonLoader />
          ) : clientSecret ? (
            <>
              <form className="space-y-[10px] mb-[11px]">
                <div className="m-0">
                  <label
                    htmlFor="fullName"
                    className="block font-poppins text-start text-[16xp] font-normal text-[#30313D]"
                  >
                    Prénom et Nom
                  </label>
                  <input
                    id="fullName"
                    type="text"
                    placeholder="Prénom et Nom"
                    {...register("fullName", {
                      required: "Le Prénom et Nom sont obligatoires",
                    })}
                    className="text-[#30313d] block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm placeholder:text-[#818289]"
                  />
                  {errors.fullName && (
                    <p className="mt-1 text-[16xp] text-red-600">
                      {errors.fullName.message}
                    </p>
                  )}
                </div>
                <div className="m-0">
                  <label
                    htmlFor="email"
                    className="block text-start font-poppins text-[16xp] font-normal text-[#30313D]"
                  >
                    Adresse mail
                  </label>
                  <input
                    id="email"
                    type="email"
                    placeholder="email@domain.com"
                    {...register("email", {
                      required: "L'email est obligatoire",
                    })}
                    className="text-[#30313d] block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm placeholder:text-[#818289]"
                  />
                  {errors.email && (
                    <p className="mt-1 text-[16xp] text-red-600">
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div className="m-0">
                  <label
                    htmlFor="password"
                    className="block font-poppins text-start text-[16xp] font-normal text-[#30313D]"
                  >
                    Mot de passe
                  </label>
                  <input
                    id="password"
                    type="password"
                    placeholder="******"
                    {...register("password", {
                      required: "Le mot de passe est obligatoire",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                    className="block text-[#30313d] w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm placeholder:text-[#818289]"
                  />
                  {errors.password && (
                    <p className="mt-1 text-[16xp] text-red-600">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </form>

              {isComponentLoading ? (
                <SkeletonLoader />
              ) : clientSecret ? (
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret, appearance }}
                >
                  <PaymentComponent
                    userDetails={watchedFields}
                    onPaymentSuccess={handlePaymentSuccess}
                    disabled={!isValid || loading}
                    clientSecret={clientSecret}
                  />
                </Elements>
              ) : (
                "Something went wrong while payment card loading..."
              )}

              <p className="mt-2 text-center text-sm text-gray-600">
                Déjà inscrit?{" "}
                <Link
                  to="/login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Connexion
                </Link>
              </p>
            </>
          ) : (
            <div>Something went wrong...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signup;
